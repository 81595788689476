import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Viewports from "../../../../../../../constants/viewports";

function InvalidDiscountCodeDialog({
  show,
  message,
  onHide,
  onAccept
}) {

  // #region Events

  const onHideEvent = () => {
    hideDialog();
  };

  const onAcceptPressEvent = () => {
    accept();
  };

  // #endregion

  // #region Methods

  const hideDialog = () => {
    if (onHide && onHide instanceof Function) {
      onHide();
    }
  };

  const accept = () => {
    if (onAccept && onAccept instanceof Function) {
      onAccept();
    }
  };

  // #endregion

  // #region Templates

  const modalTemplate = (
    <>
      <Modal
        show={show}
        centered
        onHide={onHideEvent}
        className='app-neutral-black-text app-montserrat-medium-font'
      >
        <Modal.Header closeButton className='app-tertiary-bg'>
          <Modal.Title>
            Cupón de descuento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='app-quaternary-bg'>
          <p>
            {message}
          </p>
        </Modal.Body>
        <Modal.Footer className='app-tertiary-bg'>
          <Button
            className='app-primary-button text-uppercase app-pharma-bold-font'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onHideEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Cancelar
          </Button>
          <Button
            className='app-accent-button text-uppercase app-pharma-bold-font'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onAcceptPressEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Continuar sin cupón
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  // #endregion

  return (
    <>
      {modalTemplate}
    </>
  );
}

export default InvalidDiscountCodeDialog;
