import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Viewports from "../../../../../../../constants/viewports";
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';

function DiscountCodeDialog({
  show,
  onHide,
  onAccept
}) {

  // #region Vars and Consts
    
  const [discountCode, setDiscountCode] = useState('');
    
  // #endregion
  
  // #region Events

  useEffect(() => {
    if (show) {
      reset();
    }    
  }, [show]);

  const onHideEvent = () => {
    hideDialog();
  };

  const onAcceptPressEvent = () => {
    accept(discountCode);
  };

  const onDiscountCodeChange = (e) => {
    setDiscountCode(e.target.value);
  };

  // #endregion

  // #region Methods

  const reset = () => {
    setDiscountCode('');
  };

  const hideDialog = () => {
    if (onHide && onHide instanceof Function) {
      onHide();
    }
  };

  const accept = (discountCode) => {
    if (onAccept && onAccept instanceof Function) {
      onAccept(discountCode);
    }
  };

  // #endregion

  // #region Templates

  const discountCodeFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          ¿Tienes un código de descuento? ¡Introdúcelo aquí para ahorrar!
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="discountCode"
            placeholder="Cupón de descuento"
            className='app-montserrat-regular-font app-quinary-text'
            value={discountCode}
            onChange={onDiscountCodeChange}            
          />          
        </InputGroup>
      </Form.Group>
    </>
  );

  const modalTemplate = (
    <>
      <Modal
        show={show}
        centered
        onHide={onHideEvent}
        className='app-neutral-black-text app-montserrat-medium-font'
      >
        <Modal.Header closeButton className='app-tertiary-bg'>
          <Modal.Title>
            Cupón de descuento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='app-quaternary-bg'>          
          {discountCodeFormItemTemplate}
        </Modal.Body>
        <Modal.Footer className='app-tertiary-bg'>
          <Button
            className='app-primary-button text-uppercase app-pharma-bold-font'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onAcceptPressEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Continuar sin cupón
          </Button>
          <Button
            className='app-accent-button text-uppercase app-pharma-bold-font'            
            size={Viewports.sizes.LARGE}
            onClick={onAcceptPressEvent}
            disabled={!discountCode || discountCode.trim() === ''} 
            style={{
              textDecoration: 'none'
            }}
          >
            Usar Cupón
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  // #endregion

  return (
    <>
      {modalTemplate}
    </>
  );
}

export default DiscountCodeDialog;
