import { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import underlinePurpleIamge from '../../../../../../assets/images/underlines/underline-purple.png';
import * as Paths from "../../../../../../constants/paths";
import * as Viewports from "../../../../../../constants/viewports";
import * as Program from "../../../../../../constants/program";
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import SignInDialog from '../../../../../_common/signIn/SignInDialog/SignInDialog';
import UnconfirmedEmailDialog from './UnconfirmedEmailDialog/UnconfirmedEmailDialog';
import PaymentNotPosibleDialog from './PaymentNotPosibleDialog/PaymentNotPosibleDialog';
import DiscountCodeDialog from './DiscountCodeDialog/DiscountCodeDialog';
import InvalidDiscountCodeDialog from './InvalidDiscountCodeDialog/InvalidDiscountCodeDialog';

function InvestmentBox() {

  // #region Vars and Consts
  
  const nav = useNav();
  const { hasPermission, user } = useAuth();
  const [isSignInDialogVisible, setIsSignInDialogVisible] = useState(false);
  const [signInDialogErrorMessage, setSignInDialogErrorMessage] = useState('');
  const [isUnconfirmedEmailDialogVisible, setIsUnconfirmedEmailDialogVisible] = useState(false);
  const [unconfirmedEmailDialogMessage, setUnconfirmedEmailDialogMessage] = useState(null);    
  const [isPaymentNotPosibleDialogVisible, setIsPaymentNotPosibleDialogVisible] = useState(false);
  const [paymentNotPosibleDialogMessage, setPaymentNotPosibleDialogMessage] = useState(null);  
  const [isDiscountCodeDialogVisible, setIsDiscountCodeDialogVisible] = useState(false);
  const [isInvalidDiscountCodeDialogVisible, setIsInvalidDiscountCodeDialogVisible] = useState(false);
  const [invalidDiscountCodeMessage, setInvalidDiscountCodeMessage] = useState(null);  

  // #endregion

  // #region Events

  const onPay = () => {        
    if (user) {
      showDiscountCodeDialog();      
    } else {
      showSignInDialog('Inicie sesión para poder adquirir los programas.');
    }    
  };

  const onHideLogin = () => {
    setIsSignInDialogVisible(false);
  };

  const onHideUnconfirmedEmailDialog = () => {
    setIsUnconfirmedEmailDialogVisible(false);
  };

  const onHidePaymentNotPosibleDialog = () => {
    setIsPaymentNotPosibleDialogVisible(false);
  };

  const onHideDiscountCodeDialog = () => {
    setIsDiscountCodeDialogVisible(false);
  };

  const onAcceptDiscountCodeDialog = (discountCode) => {
    setIsDiscountCodeDialogVisible(false);    
    pay(
      user.userId,
      Program.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE.code,
      discountCode
    );
  };

  const onHideInvalidDiscountCodeDialog = () => {
    setIsInvalidDiscountCodeDialogVisible(false);
  };

  const onAcceptInvalidDiscountCodeDialog = () => {
    setIsInvalidDiscountCodeDialogVisible(false);
    pay(
      user.userId,
      Program.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE.code,
      ''
    );
  };

  const onSessionExpired = () => {
    showSignInDialog('La sesión expiró, por favor inicie sesión nuevamente.')
  };

  // #endregion  

  // #region Methods

  const pay = (
    userId,
    programCode,
    discountCode
  ) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/conekta/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify({
        userId,
        programCode,
        discountCode
      }),
    }).then(response => response.json()).then(data => {      
      if (data.status === 403) {
        // Session expired
        onSessionExpired();
      } else {
        switch (data.responseCode) {
          case 'OK':
            console.log('Coneka order:', data);
            window.location.href = data.responseObject;
            break;
          case 'E03': // Unconfirmed email
            showUnconfirmedEmailDialog(data.responseMessage);
            break;
          case 'E04': // Already
            showPaymentNotPosibleDialog(data.responseMessage);
            break;
          case 'E05': // Related
            showPaymentNotPosibleDialog(data.responseMessage);
            break;
          case 'E06': // Invalid discount code
            showInvalidDiscountCodeDialog(data.responseMessage);
            break;
          default:
            break;
        }
      } 
    }, (error) => {
      console.error('Error al pagar:', error);
    });
  };

  const showSignInDialog = (errorMessage) => {
    setSignInDialogErrorMessage(errorMessage);
    setIsSignInDialogVisible(true);
  };

  const showUnconfirmedEmailDialog = (message) => {
    setUnconfirmedEmailDialogMessage(message);
    setIsUnconfirmedEmailDialogVisible(true);    
  };

  const showPaymentNotPosibleDialog = (message) => {
    setPaymentNotPosibleDialogMessage(message);
    setIsPaymentNotPosibleDialogVisible(true);
  };

  const showDiscountCodeDialog = () => {    
    setIsDiscountCodeDialogVisible(true);
  };

  const showInvalidDiscountCodeDialog = (message) => {
    setInvalidDiscountCodeMessage(message);
    setIsInvalidDiscountCodeDialogVisible(true);
  };

  // #endregion

  // #region Templates

  const investmentTemplate = (
    <>
      <Container className='p-5'>
        <h2 className='app-pharma-bold-font app-primary-text text-uppercase text-center py-3'>
          Duración e Inversión
        </h2>
        <Row className='pb-4'>
          <Col lg={4}>
            <Card className='my-2 app-tertiary-bg'>
              <Card.Body>
                <Card.Title>
                  <span className='app-montserrat-bold-font text-uppercase'>
                    &nbsp;
                  </span>
                  <h1 className='app-montserrat-bold-font text-center text-uppercase'>
                    6 semanas
                  </h1>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className='my-2 app-primary-bg'>
              <Card.Body>
                <Card.Title className='position-relative text-center'>
                  <span className='app-montserrat-bold-font text-uppercase'>
                    Equivale a
                  </span>
                  <h1 className='app-montserrat-bold-font text-center text-uppercase'>
                    12 sesiones
                  </h1>
                  <h1
                    className='app-suomi-hand-script-font app-accent-text position-absolute'
                    style={{
                      fontSize: '2rem',
                      right: '20%',
                      bottom: '-25px'
                    }}
                  >
                    Al precio de una.
                  </h1>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className='my-2 app-secondary-bg'>
              <Card.Body>
                <Card.Title className='position-relative'>
                  <h1 className='app-montserrat-bold-font app-primary-text text-center text-uppercase'>
                    $ 1,500&nbsp;
                    <span className='h4'>
                      mxp
                    </span>
                  </h1>
                  <img
                    className='w-50 position-absolute'
                    src={underlinePurpleIamge}
                    style={{
                      left: '25%',
                      bottom: '-20px'
                    }}
                  />   
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <p className='app-montserrat-medium-font app-neutral-white-text text-center fs-4'>
          Está diseñado para que lo puedas concluir en&nbsp;
          <span className='app-tertiary-text'>
            6 semanas
          </span>&nbsp;
          (una por módulo).
        </p>
        <p className='app-montserrat-medium-font app-neutral-white-text text-center fs-4'>
          La inversión es de&nbsp;
          <b className='app-primary-text'>
            1,500 MXP
          </b>,&nbsp;
          lo que equivale a recibir&nbsp;
          <b className='app-primary-text'>
            12 sesiones
          </b>&nbsp;
          terapéuticas por el precio de una.
        </p>
        <Button
          className="text-uppercase app-pharma-bold-font fs-3 p-3 shadow mx-auto d-block"
          variant='primary'
          size={Viewports.sizes.MEDIUM}
          onClick={onPay}
        >
          ¡Inscríbete ya!
        </Button>
      </Container>
    </>
  );

  const dialogsTemplates = (
    <>
      <SignInDialog
        show={isSignInDialogVisible}
        navTo={Paths.views.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE}
        errorMessage={signInDialogErrorMessage}
        onHide={onHideLogin}
      />
      <UnconfirmedEmailDialog 
        show={isUnconfirmedEmailDialogVisible}
        message={unconfirmedEmailDialogMessage}
        onHide={onHideUnconfirmedEmailDialog}
        onSessionExpired={onSessionExpired}
      />
      <DiscountCodeDialog
        show={isDiscountCodeDialogVisible}
        onHide={onHideDiscountCodeDialog}
        onAccept={onAcceptDiscountCodeDialog}
      />
      <PaymentNotPosibleDialog
        show={isPaymentNotPosibleDialogVisible}
        message={paymentNotPosibleDialogMessage}
        onHide={onHidePaymentNotPosibleDialog}      
      />
      <InvalidDiscountCodeDialog
        show={isInvalidDiscountCodeDialogVisible}
        message={invalidDiscountCodeMessage}
        onHide={onHideInvalidDiscountCodeDialog}
        onAccept={onAcceptInvalidDiscountCodeDialog}
      />
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-secondary-bg'>
        <section id={Paths.sections.programs.fourtyDaysForAnAmazingLife.INVESTMENT}>
          {investmentTemplate}
        </section>
      </article>
      {dialogsTemplates}
    </>
  );
}

export default InvestmentBox;